import React, { useState, useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { ConfirmationModal } from 'src/components/ConfirmationModal';
import { EventBus } from 'src/helpers/new';

interface UsePromptProps {
    when?: boolean;
    inExam: boolean;
    inQuiz: boolean;
}

const Prompt = ({ when = true, inExam = false, inQuiz = false }: UsePromptProps) => {
    const [isPromptVisible, setIsPromptVisible] = useState(false);

    const blocker: any = useBlocker(({ currentLocation, nextLocation }) => {
        if (when && nextLocation.state?.avoidRouteLeavingGuard !== true) {
            setIsPromptVisible(true);
            return true;
        }
        return false;
    });

    useEffect(() => {
        const handleBeforeUnload = (e: BeforeUnloadEvent) => {
            if (when) {
                e.preventDefault();
                e.returnValue = '';
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [when]);

    const handleConfirmNavigationClick = () => {
        setIsPromptVisible(false);
        if (inExam) {
            EventBus.dispatch('end-exam-proctoring');
        } else if (inQuiz) {
            EventBus.dispatch('leave-quiz');
        }
        blocker.proceed();
    };

    const closePrompt = () => {
        setIsPromptVisible(false);
        blocker?.reset();
    };

    const getTitleText = (inExam: boolean, inQuiz: boolean) => {
        if (inExam) return 'Leave Exam';
        if (inQuiz) return 'Leave Quiz?';
        return 'Leave site?';
    };

    const getBodyText = (inExam: boolean, inQuiz: boolean) => {
        if (inExam) {
            return [
                <span key='1'>
                    {` Are you sure you want to exit the exam? Please note, the exam timer will continue running in the
                    background!`}
                    <br />
                </span>,
                <span key='2'>{`Your answers will be auto submitted once timer run out !!!`} </span>,
            ];
        }

        if (inQuiz) {
            return 'Are you sure you wish to leave? you will be able to resume later if your time has not expired';
        }

        return 'Changes you made will not be saved.';
    };

    return isPromptVisible ? (
        <ConfirmationModal
            show={isPromptVisible}
            hideModal={closePrompt}
            confirmAction={handleConfirmNavigationClick}
            titleText={getTitleText(inExam, inQuiz)}
            bodyText={getBodyText(inExam, inQuiz)}
            confirmButtonText='Leave'
            className={inExam ? 'exam-route-confimation' : ''}
        />
    ) : null;
};

export default Prompt;
